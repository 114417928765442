import logo from './betULogo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { SimpleGrid, VStack, Image, Center, Divider, Heading } from '@chakra-ui/react'

function App() {
  return (
    <ChakraProvider>
      <SimpleGrid bg="#334f7b" className='TopGrid' w="100%" h="100vh" columns={1}>
        <Center>
          <VStack>
            <Heading>Coming Soon</Heading>
            <Divider orientation='horizontal' />
            <Image h='33%' w='40%' src={logo} alt='BettU Logo' />
          </VStack>
        </Center>
      </SimpleGrid>
    </ChakraProvider>
  );
}

export default App;
